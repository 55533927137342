import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

const Loadable = (Component) => {
  const WrappedComponent = (props) => {
    const { pathname } = useLocation();

    return (
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
        <Component {...props} />
      </Suspense>
    );
  };

  WrappedComponent.displayName = `Loadable(${Component.displayName || Component.name || 'Component'})`;

  return WrappedComponent;
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/list" replace />, index: true },
            { path: PATH_DASHBOARD.eCommerce.listProduct, element: <EcommerceProductList /> },
            // { path: PATH_DASHBOARD.eCommerce.listAuction, element: <EcommerceAuctionList /> },
            { path: PATH_DASHBOARD.eCommerce.newProduct, element: <EcommerceProductCreate /> },
            // { path: PATH_DASHBOARD.eCommerce.newAuction, element: <EcommerceAuctionCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'product/:name/seller', element: <EcommerceProductCreate /> },
            { path: PATH_DASHBOARD.eCommerce.stockAvailable, element: <ProductAvailableList /> },
            // { path: 'auction/:id/edit', element: <EcommerceAuctionCreate /> },
            // { path: PATH_DASHBOARD.eCommerce.place_your_bid, element: <PlaceBid /> },
            // { path: PATH_DASHBOARD.eCommerce.logAuction, element: <AuctionLogs /> },
            // { path: PATH_DASHBOARD.eCommerce.selectAuction, element: <SelectAuction /> },
            { path: PATH_DASHBOARD.eCommerce.newCask, element: <CreateCask /> },
            { path: PATH_DASHBOARD.eCommerce.editCask, element: <CreateCask /> }
          ],
        },
        // {
        //   path: PATH_DASHBOARD.cask.root,
        //   children: [
        //     { element: <Navigate to={PATH_DASHBOARD.cask.listCask} replace />, index: true },
        //     { path: PATH_DASHBOARD.cask.listCask, element: <EcommerceProductList /> },
        //     { path: PATH_DASHBOARD.cask.newCask, element: <CreateCask /> },
        //   ],
        // },
        {
          path: PATH_DASHBOARD.auction.root,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.auction.list} replace />, index: true },
            { path: PATH_DASHBOARD.auction.list, element: <EcommerceAuctionList /> },
            { path: PATH_DASHBOARD.auction.new, element: <EcommerceAuctionCreate /> },
            { path: ':id/edit', element: <EcommerceAuctionCreate /> },
            { path: PATH_DASHBOARD.auction.place_your_bid, element: <PlaceBid /> },
            { path: PATH_DASHBOARD.auction.log, element: <AuctionLogs /> },
            { path: PATH_DASHBOARD.auction.select, element: <SelectAuction /> },
            { path: PATH_DASHBOARD.auction.schedule, element: <ScheduleList /> }
          ],
        },
        {
          path: 'attribute',
          children: [
            { path: PATH_DASHBOARD.attribute.productClass, element: <ProductClass /> },
            {
              path: PATH_DASHBOARD.attribute.productType, element: <ProductType />
            },
            {
              path: PATH_DASHBOARD.attribute.productCategoryDram, element: <ProductCategory />
            },
            {
              path: PATH_DASHBOARD.attribute.productCategoryBottle, element: <ProductCategory />
            },
            {
              path: PATH_DASHBOARD.attribute.productDistillery, element: <ProductDistillery />
            },
            { path: PATH_DASHBOARD.attribute.productLottype, element: <ProductLottype /> },
            { path: PATH_DASHBOARD.attribute.productRegion, element: <ProductRegion /> },
            { path: PATH_DASHBOARD.attribute.productLocation, element: <ProductLocation /> },
          ],
        },
        {
          path: 'order',
          children: [
            { path: PATH_DASHBOARD.order.list, element: <OrderList /> },
            { path: PATH_DASHBOARD.order.storage, element: <OrderStorages /> }
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: PATH_DASHBOARD.user.topSpender, element: <TopSpender /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserAccount /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> },
            { path: PATH_DASHBOARD.blog.editPost, element: <BlogNewPost /> },
          ],
        },
        {
          path: 'comment',
          children: [
            { element: <Navigate to="/dashboard/comment/list" replace />, index: true },
            { path: 'list', element: <CommentList /> },
          ],
        },
        {
          path: 'submit-share',
          children: [
            { element: <Navigate to="/dashboard/submit-share/list" replace />, index: true },
            { path: 'list', element: <SubmitAndShareList /> },
            { path: PATH_DASHBOARD.submitandshare.seller, element: <SellerManagement /> },
            { path: PATH_DASHBOARD.submitandshare.requestValuation, element: <RequestValuationList /> }
          ],
        },
        {
          path: PATH_DASHBOARD.reviewandrating.root,
          children: [
            {
              path: PATH_DASHBOARD.reviewandrating.create,
              element: <ReviewCreate />
            },
            {
              path: PATH_DASHBOARD.reviewandrating.list,
              element: <ReviewAndRatingList />
            },
            {
              path: PATH_DASHBOARD.reviewandrating.send,
              element: <SendReview />
            },
            {
              path: PATH_DASHBOARD.reviewandrating.edit,
              element: <ReviewCreate />
            }
          ],
        },
        {
          path: PATH_DASHBOARD.shippings.root,
          children: [
            { element: <Navigate to="/dashboard/shipping/list/dram" replace />, index: true },
            { path: PATH_DASHBOARD.shippings.girds, element: <ShippingGird /> },
            { path: PATH_DASHBOARD.shippings.girdsdram, element: <ShippingGird /> },
            { path: PATH_DASHBOARD.shippings.girdsbottle, element: <ShippingGird /> },
            { path: PATH_DASHBOARD.shippings.dram, element: <ShippingList /> },
            { path: PATH_DASHBOARD.shippings.bottle, element: <ShippingList /> },
            { path: PATH_DASHBOARD.shippings.new, element: <ShippingCreate /> },
            { path: PATH_DASHBOARD.shippings.edit, element: <ShippingCreate /> },
          ],
        },
        {
          path: PATH_DASHBOARD.settings.root,
          children: [
            { element: <Navigate to="/dashboard/settings/general" replace />, index: true },
            { path: PATH_DASHBOARD.settings.general, element: <SettingGeneral /> },
            { path: PATH_DASHBOARD.settings.company, element: <SettingCompany /> },
            { path: PATH_DASHBOARD.settings.shipping, element: <SettingShipping /> },
            { path: PATH_DASHBOARD.settings.payment, element: <SettingPayment /> },
            { path: PATH_DASHBOARD.settings.email, element: <SettingEmail /> },
            { path: PATH_DASHBOARD.settings.content, element: <SettingContent /> },
            { path: PATH_DASHBOARD.settings.social, element: <SettingSocial /> },
            { path: PATH_DASHBOARD.settings.tax, element: <SettingTax /> },
          ],
        },
        {
          path: PATH_DASHBOARD.content.root,
          element: <SettingContent />,
        },
        {
          path: PATH_DASHBOARD.settings.banner,
          element: <SettingBanner />,
        },
        {
          path: PATH_DASHBOARD.newsletter.root,
          children: [
            { element: <Navigate to="/dashboard/newsletter/list" replace />, index: true },
            { path: PATH_DASHBOARD.newsletter.list, element: <Subscribers /> },
            { path: PATH_DASHBOARD.newsletter.create_template, element: <TemplateCreate /> },
            { path: PATH_DASHBOARD.newsletter.newsletters, element: <Newsletters /> },
            { path: PATH_DASHBOARD.newsletter.edit_template, element: <TemplateCreate /> },
            { path: PATH_DASHBOARD.newsletter.send_email, element: <SendEmails /> },
            { path: PATH_DASHBOARD.newsletter.subscribers, element: <Subs /> },
            { path: PATH_DASHBOARD.newsletter.unsubscribe, element: <Unsubscribe /> }
          ],
        },
        {
          path: PATH_DASHBOARD.glossary.root,
          children: [
            { element: <Navigate to="/dashboard/glossary/list" replace />, index: true },
            { path: PATH_DASHBOARD.glossary.list, element: <GlossaryList /> },
            { path: PATH_DASHBOARD.glossary.new, element: <GlossaryCreate /> },
            { path: PATH_DASHBOARD.glossary.edit, element: <GlossaryCreate /> },
          ],
        },
        {
          path: PATH_DASHBOARD.library.root,
          element: <Library />,

        },
        {
          path: PATH_DASHBOARD.wishlist.root,
          element: <Wishlist />,
        },
        {
          path: PATH_DASHBOARD.independent.root,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.independent.list} replace />, index: true },
            { path: PATH_DASHBOARD.independent.list, element: <IndependentList /> },
            { path: PATH_DASHBOARD.independent.new, element: <IndependentCreate /> },
            { path: PATH_DASHBOARD.independent.edit, element: <IndependentCreate /> },
          ],
        },
        {
          path: PATH_DASHBOARD.faq.root,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.faq.list} replace />, index: true },
            { path: PATH_DASHBOARD.faq.list, element: <FAQList /> },
            { path: PATH_DASHBOARD.faq.new, element: <FAQCreate /> },
            { path: PATH_DASHBOARD.faq.edit, element: <FAQCreate /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: PATH_DASHBOARD.collect.root, element: <Collect /> }
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        // { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/help',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    {
      path: '/',
      element: <AuthGuard>
        <DashboardLayout />
      </AuthGuard>,
      children: [
        { element: <GeneralApp />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceAuctionList = Loadable(lazy(() => import('../pages/dashboard/EcommerceAuctionList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')))
const EcommerceAuctionCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceAuctionCreate')))
const ProductAvailableList = Loadable(lazy(() => import('../pages/dashboard/product_available/ProductAvailableList')))
const ProductClass = Loadable(lazy(() => import('../pages/dashboard/attribute/ProductClass')));
const ProductType = Loadable(lazy(() => import('../pages/dashboard/attribute/ProductType')));
const ProductCategory = Loadable(lazy(() => import('../pages/dashboard/attribute/ProductCategory')));
const ProductDistillery = Loadable(lazy(() => import('../pages/dashboard/attribute/ProductDistillery')));
const ProductLottype = Loadable(lazy(() => import('../pages/dashboard/attribute/ProductLottype')));
const ProductRegion = Loadable(lazy(() => import('../pages/dashboard/attribute/ProductRegion')));
const ProductLocation = Loadable(lazy(() => import('../pages/dashboard/attribute/ProductLocation')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const CommentList = Loadable(lazy(() => import('../pages/dashboard/comment/CommentList')));

const SubmitAndShareList = Loadable(lazy(() => import('../pages/dashboard/submit_lot/SubmitAndShareList')));
const RequestValuationList = Loadable(lazy(() => import('../pages/dashboard/submit_lot/RequestValuationList')));
const SellerManagement = Loadable(lazy(() => import('../pages/dashboard/submit_lot/SellerManagement')));

const IndependentList = Loadable(lazy(() => import('../pages/dashboard/independent/IndependentList')));
const IndependentCreate = Loadable(lazy(() => import('../pages/dashboard/independent/IndependentCreate')));

const ReviewAndRatingList = Loadable(lazy(() => import('../pages/dashboard/review_rating/ReviewRatingList')));
const ReviewsRatings = Loadable(lazy(() => import('../pages/dashboard/review_rating/ReviewsRatings')));
const ShippingList = Loadable(lazy(() => import('../pages/dashboard/shipping/ShippingList')));
const ShippingGird = Loadable(lazy(() => import('../pages/dashboard/shipping/ShippingGird')));
const ShippingCreate = Loadable(lazy(() => import('../pages/dashboard/shipping/ShippingCreate')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const OrderList = Loadable(lazy(() => import('../pages/dashboard/order/OrderList')));
const OrderStorages = Loadable(lazy(() => import('../pages/dashboard/order/OrderStorages')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

const GlossaryList = Loadable(lazy(() => import('../pages/dashboard/glossary/GlossaryList')));
const GlossaryCreate = Loadable(lazy(() => import('../pages/dashboard/glossary/GlossaryCreate')));

const Subscribers = Loadable(lazy(() => import('../pages/dashboard/newsletter/Subscribers')));
const TemplateCreate = Loadable(lazy(() => import('../pages/dashboard/newsletter/TemplateCreate')));
const Newsletters = Loadable(lazy(() => import('../pages/dashboard/newsletter/Newsletters')));
const SendEmails = Loadable(lazy(() => import('../pages/dashboard/newsletter/SendEmails')));
const Subs = Loadable(lazy(() => import('../pages/dashboard/newsletter/Subs')));
const Unsubscribe = Loadable(lazy(() => import('../pages/dashboard/newsletter/Unsubscribe')));

//Settings
const SettingGeneral = Loadable(lazy(() => import('../pages/dashboard/setting/General')));
const SettingContent = Loadable(lazy(() => import('../pages/dashboard/setting/Content')));
const SettingEmail = Loadable(lazy(() => import('../pages/dashboard/setting/Email')));
const SettingPayment = Loadable(lazy(() => import('../pages/dashboard/setting/Payment')));
const SettingShipping = Loadable(lazy(() => import('../pages/dashboard/setting/Shipping')));
const SettingTax = Loadable(lazy(() => import('../pages/dashboard/setting/Tax')));
const SettingSocial = Loadable(lazy(() => import('../pages/dashboard/setting/Social')));
const SettingCompany = Loadable(lazy(() => import('../pages/dashboard/setting/Company')));
const SettingBanner = Loadable(lazy(() => import('../pages/dashboard/setting/Banner')));
// Logs
const AuctionLogs = Loadable(lazy(() => import('../pages/dashboard/logs/AuctionLogs')));
const PlaceBid = Loadable(lazy(() => import('../pages/dashboard/placebid/PlaceBid')));
const SelectAuction = Loadable(lazy(() => import('../pages/dashboard/select_auction/SelectAuction')));
const ScheduleList = Loadable(lazy(() => import('../pages/dashboard/auction/ScheduleList')));

// Libraries
const Library = Loadable(lazy(() => import('../pages/dashboard/library')));

// Wishlist
const Wishlist = Loadable(lazy(() => import('../pages/dashboard/wishlist')));

// FAQ
const FAQList = Loadable(lazy(() => import('../pages/dashboard/faq/FAQList')));
const FAQCreate = Loadable(lazy(() => import('../pages/dashboard/faq/FAQCreate')));

const Collect = Loadable(lazy(() => import('../pages/dashboard/collect/Collect')));

// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// Cask
const CreateCask = Loadable(lazy(() => import('../pages/dashboard/cask/CreateCask')));

const TopSpender = Loadable(lazy(() => import('../pages/dashboard/user/TopSpender')));

const ReviewCreate = Loadable(lazy(() => import('../pages/dashboard/review_rating/ReviewCreate')));
const SendReview = Loadable(lazy(() => import('../pages/dashboard/review_rating/SendReview')));